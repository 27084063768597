/* ----------------------------------------------------------------
[Table of contents]

01. Google Fonts
02. Default style
03. Scrollbar
04. Selection
05. Owl Carousel custom style
06. Sidebar style
07. Main style
08. Navigation style
09. Header style
10. Title style
11. Bride & Groom style
12. Wedding Countdown style
13. Our Story style
14. Friends style
15. Organization style
16. See You style
17. Gallery style
18. Gift Registry style
19. When & Where style
20. Gift style
21. Button style
22. Blog & Pst style
23. Footer style
24. Media Query style

------------------------------------------------------------------- */


/* ===== Google Fonts ===== */
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');



/* ===== Default style ===== */
html,
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
}
body {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.75em;
    color: #888;
}
/*** typography ***/
/* headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    line-height: 1.25em;
    margin: 0 0 20px 0;
}
/* paragraph */
p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.75em;
    color: #888;
    margin: 0 0 20px;
}
/* lists */
ul {
    list-style-type: none;
}
/* links */
a {
    color: #000;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
a:hover,
a:active,
a:focus {
    color: #000;
    outline: none;
    text-decoration: none !important;
}
a:link {
    text-decoration: none;
}
a:focus {
    outline: none;
}
img {
    width: 100%;
    height: auto;
}
.small,
small {
    font-size: 80%;
}
b,
strong {
    color: #000;
    font-weight: 600;
}
span,
a,
a:hover {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}
hr {
    margin-top: 0px;
    margin-bottom: -20px;
    border: 0;
}
/* table */
table,
th,
tr,
td {
    border: 1px solid #000;
}
th,
tr,
td {
    padding: 10px;
}

/*** form element ***/
/* Apply font family to form elements */
button,
input,
optgroup,
select,
textarea {
    font-family: 'Nunito Sans', sans-serif;
}

/* Style for focus on input elements */
input[type="password"]:focus,
input[type="email"]:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
textarea:focus,
select:focus {
    outline: none;
    border-bottom-width: 1px;
}

/* Style for input and textarea elements */
input[type="password"],
input[type="email"],
input[type="text"],
input[type="file"],
textarea,
#message,
#search,
select {
    max-width: 100%;
    margin-bottom: 15px;
    border: none;
    padding: 7px 15px;
    height: auto;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: block;
    width: 100%;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.75em;
    color: #000;
    background-image: none;
    border: 1px solid #ececec;
}

/* Additional style for select element */
.select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.select-wrapper select {
    width: 100%;
    padding-left: 10px;
    border-radius: 0px;
    -webkit-appearance: none; /* Remove default styling on Chrome */
    -moz-appearance: none; /* Remove default styling on Firefox */
    appearance: none; /* Remove default styling */
    background: #fff url('data:image/svg+xml;utf8,<svg fill="%23777" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
    background-size: 12px;
    border: 1px solid #ececec;
    cursor: pointer;
    transition: border-color 0.3s ease;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 13px;
    max-height: 40px;
}

.select-wrapper select:focus {
    border-color: #BD945A; /* Match focus border color */
    outline: none;
}

.select-wrapper::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    pointer-events: none;
    transform: translateY(-50%);
}

/* Remove default arrow in IE */
select::-ms-expand {
    display: none;
}

/* Existing styles */
select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ececec;
    cursor: pointer;
}


input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    font-family: 'Nunito Sans', sans-serif;
    background: #BD945A;
    border: 1px solid #BD945A;
    color: #fff;
    padding: 15px 30px;
    margin: 0;
    font-weight: 400;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 0px;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover {
    background-color: transparent;
    color: #000;
}

select {
    padding: 10px;
    border-radius: 5px;
}
input[type="radio"],
input[type="checkbox"] {
    /*display: inline;*/
}
label {
    color: #777;
    transition: all .3s cubic-bezier(.64, .09, .08, 1);
    display: inline-block;
    -moz-appearance: none;
    display: inline-block;
    vertical-align: middle;
        font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.75em;
    margin-bottom: 0;
}
input[type=radio]:after,
input[type=radio]:before {
    content: '';
    position: absolute;
    transition: all .3s cubic-bezier(.64, .09, .08, 1)
}
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox']:before {
    content: '';
    width: 18px;
    height: 18px;
    border: 1px solid #ececec;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
  }
  input[type='checkbox']:after {
    content: '\2713';
    font-size: 14px;
    color: transparent;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
  }
  input[type='checkbox']:checked:before {
    background-color: #BD945A;
    border-color: #BD945A;
  }
  input[type='checkbox']:checked:after {
    color: #fff;
  }
  .checkbox-label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
  }
  .checkbox-text {
    height: 18px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
  }
  .conditional-field {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease, max-height 0.5s ease, transform 0.5s ease;
    transform-origin: top;
  }
  .conditional-field.visible {
    opacity: 1;
    max-height: 1000px;
    transform: scaleY(1);
  }
  
input[type=radio] {
    position: relative;
    cursor: pointer;
    margin: 5px 20px 15px 0px;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 10px;
}
input[type=radio]:before {
    background-color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: inline-block;
    top: 0px;
    left: -4px;
    background-image: radial-gradient(circle, #BD945A 50%, #fff 70%);
    background-size: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    will-change: background-size;
    z-index: 2;
}
input[type=radio]:after {
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%
}
input[type=radio]:checked:before {
    background-size: 14px 14px
}
.mb-15 {
    margin-bottom: 15px;
}

.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    border: none;
    border-bottom: 0px solid #e5e5e5;
    height: 54px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    padding: 0;
    font-family: 'Cormorant Garamond', serif;
    font-size: 18px;
    line-height: 1.75em;
    font-weight: 400;
}
.form-control:active,
.form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
    border: 1px solid #ececec;
}
#message {
    font-family: 'Nunito Sans', sans-serif;
    height: 130px;
    padding: 12px;
    border: 1px solid #ececec;
}
/* padding */
.p-40 {
        padding: 40px
}
/* margin */
.mb-0 {
    margin-bottom: 0;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-60 {
    margin-bottom: 60px;
}
/* important */
.container-fluid {
    padding-right: 30px;
    padding-left: 30px;
}
.container {
    padding-right: 60px;
    padding-left: 60px;
}
.section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
}
.js .animate-box {
    opacity: 0;
}
.clear {
    clear: both;
}
.o-hidden {
    overflow: hidden;
}
.pos-re {
    position: relative;
}
.full-width {
    width: 100% !important;
}
.bg-img {
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-fixed {
    background-attachment: fixed;
}
.bg-cover {
    background-size: cover !important;
}
.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}
.valign {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.bg-pink {
    background-color: #faf8f7;
}
.bg-white {
    background-color: #fff;
}
@media screen and (max-width: 768px) {
    .bg-fixed {
        background-attachment: scroll;
    }
}


/* ===== Scrollbar ===== */
::-webkit-scrollbar {
    width: 0px;
}
::-webkit-scrollbar-track {
    background: #fff;
}
::-webkit-scrollbar-thumb {
    background: #BD945A;
}
::-webkit-scrollbar-thumb:hover {
    background: #BD945A;
}


/* ===== Selection ===== */
::-webkit-selection {
    color: #fff;
    background: rgba(189, 148, 90, 0.75);
}
::-moz-selection {
    color: #fff;
    background: rgba(189, 148, 90, 0.75);
}
::selection {
    color: #fff;
    background: rgba(189, 148, 90, 0.75);
}


/* ===== Owl Carousel custom style ===== */
.owl-theme .owl-nav.disabled + .owl-dots {
    line-height: .7;
}
.owl-theme .owl-dots .owl-dot span {
    width: 6px;
    height: 6px;
    margin: 0 2px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #BD945A;
}
.owl-theme .owl-nav [class*=owl-] {
    background-color: #BD945A;
    width: 30px;
    height: 30px;
    line-height: 25px;
    border-radius: 50%;
}
.owl-theme .owl-nav [class*=owl-] {
    color: #FFF;
    font-size: 12px;
    margin: 5px;
    padding: 4px 7px;
    background: #BD945A;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-] {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.6);
    width: 30px;
    height: 30px;
    line-height: 25px;
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.6);
}
.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: transparent;
    border: 1px solid #BD945A;
    color: #BD945A;
}


/* ===== Sidebar style ===== */
#oliven-page {
    width: 100%;
    overflow: hidden;
    position: relative;
}
#oliven-aside {
    padding: 60px 30px;
    width: 22%;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    overflow-y: scroll;
    z-index: 1001;
    background: #F6F1F0;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    scrollbar-width: none;
}
@media screen and (max-width: 1200px) {
    #oliven-aside {
        width: 30%;
    }
}
@media screen and (max-width: 768px) {
    #oliven-aside {
        width: 270px;
        -moz-transform: translateX(-270px);
        -webkit-transform: translateX(-270px);
        -ms-transform: translateX(-270px);
        -o-transform: translateX(-270px);
        transform: translateX(-270px);
        padding-top: 30px;
    }
}
#oliven-aside .oliven-logo {
    text-align: center;
    font-family: 'Cormorant Garamond', serif;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    position: relative;
    color: #000;
    line-height: 1.2em;
    letter-spacing: 0px;
}
#oliven-aside .oliven-logo span {
        font-family: 'Alex Brush', cursive;
    font-weight: 400;
    text-transform: none;
    text-align: center;
    font-size: 24px;
    color: #fff;
}
#oliven-aside .oliven-logo h6 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    text-transform: none;
    text-align: center;
    font-size: 10px;
    letter-spacing: 5px;
    color: #000;
    margin-bottom: 5px;
}
#oliven-aside .oliven-logo img {
    width: 90px;
    margin-bottom: 0px;
}
@media screen and (max-width: 768px) {
    #oliven-aside .oliven-logo {
        margin-bottom: 60px;
    }
}
#oliven-aside .oliven-logo a {
    display: inline-block;
}
#oliven-aside .oliven-logo a span {
    display: block;
    font-size: 36px;
    color: #BD945A;
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    display: block;
    margin-top: 0px;
    text-align: center;
    line-height: 1.5em;
}
#oliven-aside .oliven-logo a i {
    color: #fff;
    font-size: 16px;
    vertical-align: super;
}
#oliven-aside .oliven-main-menu ul {
    text-align: center;
    margin: 0;
    padding: 0;
}
@media screen and (max-width: 768px) {
    #oliven-aside .oliven-main-menu ul {
        margin: 0 0 30px 0;
    }
}
#oliven-aside .oliven-main-menu ul li {
    margin: 3px 0 3px 0;
    padding: 0 0 3px 0;
    list-style: none;
}
#oliven-aside .oliven-main-menu ul li a {
    color: #000;
    text-decoration: none;
    position: relative;
    padding: 0;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-bottom: 1px solid transparent;
}
#oliven-aside .oliven-main-menu ul li a:hover {
    border-bottom: 1px solid #BD945A;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}
#oliven-aside .oliven-main-menu ul li.oliven-active a {
    color: #BD945A;
}
#oliven-aside .oliven-main-menu ul li.oliven-active a:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
}
#oliven-aside .oliven-main-menu ul li a span:hover {
    color: #000;
}
#oliven-aside .footer1 {
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
    text-align: center;
}
#oliven-aside .footer1 p {
    font-size: 12px;
    color: #888;
    letter-spacing: 0.5px;
}
#oliven-aside .footer1 img {
    width: 75px;
}
#oliven-aside .footer1 a {
    color: #BD945A;
}
#oliven-aside .separator {
    width: 60px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
     border-bottom: 1px solid rgba(189, 148, 90,0.4);
    margin: 5px auto;
}
@media screen and (max-width: 768px) {
    #oliven-aside .footer1 {
        position: relative;
        padding-top: 60px;
    }
}


/* ===== Main style ===== */
#oliven-main {
    width: 78%;
    float: right;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
@media screen and (max-width: 1200px) {
    #oliven-main {
        width: 70%;
    }
}
@media screen and (max-width: 768px) {
    #oliven-main {
        width: 100%;
    }
}


/* ===== Navigation styles ===== */
.oliven-nav-toggle {
    cursor: pointer;
    text-decoration: none;
}
.oliven-nav-toggle.active i::before,
.oliven-nav-toggle.active i::after {
    background: #fff;
}
.oliven-nav-toggle.dark.active i::before,
.oliven-nav-toggle.dark.active i::after {
    background: #000;
}
.oliven-nav-toggle:hover,
.oliven-nav-toggle:focus,
.oliven-nav-toggle:active {
    outline: none;
    border-bottom: none !important;
}
.oliven-nav-toggle i {
    position: relative;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    width: 19px;
    height: 1px;
    color: #fff;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #fff;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    top: -4px;
}
.oliven-nav-toggle i::before,
.oliven-nav-toggle i::after {
    content: '';
    width: 20px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}
.oliven-nav-toggle.dark i {
    position: relative;
    color: #000;
    background: #000;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
}
.oliven-nav-toggle.dark i::before,
.oliven-nav-toggle.dark i::after {
    background: #000;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}
.oliven-nav-toggle i::before {
    top: -7px;
}
.oliven-nav-toggle i::after {
    bottom: -7px;
}
.oliven-nav-toggle:hover i::before {
    top: -10px;
}
.oliven-nav-toggle:hover i::after {
    bottom: -10px;
}
.oliven-nav-toggle.active i {
    background: transparent;
}
.oliven-nav-toggle.active i::before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}
.oliven-nav-toggle.active i::after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
}
.oliven-nav-toggle {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9999;
    cursor: pointer;
    opacity: 1;
    visibility: hidden;
    padding: 12px 15px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: #BD945A;
    margin: 15px 30px;
    border-radius: 50%;
}
@media screen and (max-width: 768px) {
    .oliven-nav-toggle {
        opacity: 1;
        visibility: visible;
    }
}
body.slide {
    overflow-x: hidden;
}
body.slide #oliven-aside {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    width: 270px;
    z-index: 999;
    position: fixed;
}
body.slide #oliven-main,
body.slide .oliven-nav-toggle {
    top: 0;
    -moz-transform: translateX(270px);
    -webkit-transform: translateX(270px);
    -ms-transform: translateX(270px);
    -o-transform: translateX(270px);
    transform: translateX(270px);
}

/* ======= Header style ======= */
.header {
    min-height: 100vh;
    overflow: hidden;
}
.header .caption .o-hidden {
    display: inline-block;
}
.header .caption h5 {
   font-family: 'Nunito Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 5px;
    margin-bottom: 0;
    color: #fff;
    text-transform: uppercase;
}
.header .caption h1 {
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    margin: 0 0 15px 0;
    font-size: 120px;
    color: #FFF;
    display: block;
    line-height: 1em;
    position: relative;
}
.header .caption p {
    color: #fff;
}
.header.parallaxie {
  background-image: url(./assets/images/slider.jpg);
}
@media screen and (max-width: 991px) {
    .header {
        background-position: 67% center;
    }
    .header .caption h1 {
        font-size: 40px;
    }
    .header .caption h5 {
        font-size: 12px;
    }
}
/* bounce */
.header .arrow {
    position: relative;
    top: 27vh;
    width: 100%;
    text-align: center;
    z-index: 8;
}
.header .arrow.bounce {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}
.header .arrow i {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 20px;
}
.header .arrow i:hover:after {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
}


/* ===== Title style ===== */
.oliven-title {
    color: #000;
    font-family: 'Cormorant Garamond', serif;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.oliven-title span {
    display: block;
}
.oliven-story-title {
    color: #000;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    font-size: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.oliven-story-subtitle {
    display: block;
    position: relative;
    top: 12px;
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    font-size: 30px;
    color: #BD945A;
    line-height: 1.25em;
    margin-bottom: 10px;
}
.oliven-couple-title {
     display: block;
    position: relative;
    top: 12px;
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    font-size: 60px;
    color: #BD945A;
    line-height: 1.5em;
    margin-bottom: 5px;
}
.oliven-couple-subtitle {
    color: #000;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0;
}
.oliven-title-meta {
    display: block;
    position: relative;
    top: 12px;
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    font-size: 30px;
    color: #BD945A;
    line-height: 1.5em;
    margin-bottom: 10px;
}


/* ======= Bride & Groom style ======= */
.bridegroom .item {
  padding: 30px;
  border-radius: 0px;
  background: #fff;
  position: relative;
  overflow: hidden;
}
.bridegroom .img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
}
.bridegroom .info {
  margin-left: 160px;
}
.bridegroom .info h6 {
    font-size: 24px;
    margin-bottom: 5px;
}
.bridegroom .info h6 i {
    color: #BD945A;
    font-size: 16px;
}
.bridegroom .info span {
      color: #BD945A;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Alex Brush', cursive;
}
.bridegroom .info p {
    margin-top: 10px;
}
.bridegroom .info .social {
  margin-top: 10px;
}
.bridegroom .info .social a {
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #eee;
  color: #888;
  margin-right: 10px;
  font-size: 12px;
}
.bridegroom .info .social a:hover {
  background-color: #BD945A;
  border-color: #BD945A;
  color: #fff;
}
.bridegroom .toright .img {
  float: right;
}
.bridegroom .toright .info {
  margin-left: 0;
  margin-right: 160px;
  text-align: right;
}
.bridegroom .toright .info .social a {
  margin-right: 0;
  margin-left: 10px;
}

@media screen and (max-width: 991px) {
.bridegroom .img {
    margin: auto;
    float: none !important;
}
.bridegroom .info {
    margin: 30px 0 0 0 !important;
}
    
    .bridegroom .item, .bridegroom .toright .info {
    text-align: center;
}
    
    }


/* ======= Wedding Countdown style ======= */
#countdown {
    text-align: center;
    background-image: url(./assets/images/banner-1.jpg);
}

#countdown .section-head {
    margin-bottom: 15px;
    z-index: 1;
}

#countdown .section-head span {
    display: block;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    margin-bottom: 0;
}

#countdown .section-head h4 {
    position: relative;
    color: #fff;
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    font-size: 60px;
    margin-bottom: 0;
}

#countdown .section-head p {
    color: #FFF;
    font-size: 24px;
    margin-bottom: 0;
}

#countdown ul {
    margin-bottom: 0;
    padding: 0;
}

#countdown li {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    display: inline-block;
    font-size: 15px;
    list-style-type: none;
    padding: 15px;
    color: #fff;
    line-height: 3em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

#countdown li span {
    display: block;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    letter-spacing: -1px;
}

@media all and (max-width: 768px) {
  #countdown {
    background-image: url(./assets/images/banner-1-phone.jpg);
    background-position: top right; /* Move the background image down and to the right */
  }

  #countdown li {
    font-size: 12px;
    padding: 15px;
  }

  #countdown li span {
    font-size: 60px;
  }
}



/* ======= Our Story style ======= */
.story .story-img {
    position: relative;
}
.story .story-img .img {
    padding: 0 30px 30px 15px;
    position: relative;
}
.story .story-img .img:before {
    content: '';
    position: absolute;
    top: 30px;
    right: 0;
    left: 45px;
    bottom: 0;
    border: 10px solid #BD945A;
}
.story .story-img .img img {
    position: relative;
    z-index: 2;
}
.story .story-img:after {
    content: '';
    width: 90%;
    position: absolute;
    top: -18%;
    bottom: -25%;
    left: 0;
    /* background-image: url(../images/dots.png); */
    background-repeat: repeat;
    z-index: -1;
}

.story-img-2 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative
}

.story-img .story-img-2 {
    width: 30% !important;
    height: 120px;
    position: absolute;
    top: 15px;
    right: 45px;
    z-index: 3;
}

/* story-wedding */

.story-wedding {
    -webkit-animation: rotation 12s infinite linear;
}
@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}


@media screen and (max-width: 991px) {
.story-img .story-img-2 {
    width: 30% !important;
    height: 135px;
    position: absolute;
    top: 40px;
    right: 45px;
}
}


@media screen and (max-width: 768px) {
    .story-img .story-img-2 {
    width: 40% !important;
    height: 120px;
    position: absolute;
    top: 15px;
    right: 45px;
    }
}


/* ===== Organization style  ===== */
.organization .bord-box {
  overflow: hidden;
  background-image: url(./assets/images/slider.jpg);
}
.organization .item-box {
  padding: 60px 30px;
  background-color: #fff;
  border: 1px solid #F6F1F0;
  border-right: 0;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s; }
.organization .item-box:last-of-type {
    border-right: 1px solid #F6F1F0; 
}
.organization .item-box:hover {
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff; }
.organization .item-box:hover p {
      color: #fff; 
}
.organization .item-box:hover .title {
      color: #FFF; 
}
.organization .item-box .numb {
    font-size: 75px;
    margin-bottom: 15px;
    color: transparent;
    -webkit-text-stroke: 1px #BD945A; 
}
.organization .item-box .title {
   font-size: 21px;
    margin-top: 10px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: 'Cormorant Garamond', serif;
    color: #000;

}
.organization .item-box.bg-img {
    position: relative;
    border: 0;
    z-index: 2; }
.organization .item-box.bg-img:hover:after {
      background: rgba(0, 0, 0, 0.4); 
}
.organization .item-box.bg-img:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      -webkit-transition: all .4s;
      -o-transition: all .4s;
      transition: all .4s;
      z-index: -1; 
}
.organization.item-box:hover .numb, .organization.item-box:hover .title {
      color: #fff; 
}


/* ======= See You style ======= */
.seeyou {
  background-image: url(./assets/images/banner-3.jpg);
}
.seeyou .section-head span {
    color: #fff;
    font-size: 30px;
    margin-bottom: 0;
}
.seeyou .section-head h3 {
    display: block;
    position: relative;
    top: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    font-size: 50px;
    color: #fff;
    margin-bottom: 0;
    line-height: 1em;
}
.seeyou .section-head h4 {
    position: relative;
    color: #fff;
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    font-size: 60px;
    margin-bottom: 0;
}

@media all and (max-width: 768px) {
    .seeyou {
      background-position: 50% bottom; /* Move the background image */
    }
  }


/* =====  Gallery style  ===== */
.gallery-menu li a {
    cursor: pointer;
    display: block;
    color: #888;
    margin-bottom: 5px;
    padding: 5px 15px;
    transition: all 0.5s;
    margin-top: 5px;
}

.gallery-menu a.active,
.gallery-menu a:hover {
    color: #fff;
    background-color: #BD945A;
}

.gallery-item {
    position: relative;
    padding: 15px;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    flex: 1 1 auto; /* Allow the item to grow and shrink as needed */
    max-width: 100%; /* Prevent the item from exceeding the width of its container */
}

.gallery-box {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    width: 100%;
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.gallery-box .gallery-img {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    width: 100%;
    height: auto; /* Adjust height automatically based on content */
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-box .gallery-img:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
    pointer-events: none; /* Prevents these elements from intercepting touch events */
}


.gallery-box .gallery-img > img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the container */
    transition: transform 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);
    border-radius: 0;
}

.gallery-box .gallery-detail {
    opacity: 0;
    color: #FFF;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0; /* Start from the top */
    transform: translateY(100%); /* Initially hidden */
    overflow: hidden;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
    text-align: center;
}

.gallery-box .gallery-detail h4 {
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    font-size: 24px;
    color: #fff;
}

.gallery-box .gallery-detail p {
    color: #fff;
}

.gallery-box:hover .gallery-detail {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    animation: fadeOutDetail 1s forwards 1s; /* Start the animation after 1 second */
}

.gallery-box:hover .gallery-img:after {
    background: rgba(0, 0, 0, 0.4);
    animation: fadeOutOverlay 1s forwards 1s; /* Start the animation after 1 second */
}

.gallery-box:hover .gallery-img > img {
    transform: scale(1.05);
    animation: scaleBack 1s forwards 1s; /* Start the animation after 1 second */
}

/* Keyframes for the fade-out and scaling animations */
@keyframes fadeOutDetail {
    0% { opacity: 1; }
    100% { opacity: 0; transform: translateY(100%); }
}

@keyframes fadeOutOverlay {
    0% { background: rgba(0, 0, 0, 0.4); }
    100% { background: transparent; }
}

@keyframes scaleBack {
    0% { transform: scale(1.05); }
    100% { transform: scale(1); }
}




/* ===== When & Where style ===== */
/* Ensure parent container uses flexbox and fills the available space */
.whenwhere {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap if necessary */
    justify-content: space-between; /* Distribute space between items */
}

/* Flex container for each item */
.whenwhere .item {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex: 1 1 calc(33.33% - 20px); /* Allow items to grow and shrink, adjust width to three per row with spacing */
    margin: 10px; /* Add spacing between items */
    box-sizing: border-box; /* Include padding and border in item size */
}

/* Fixed dimensions for the image container */
.whenwhere .item .whenwhere-img {
    position: relative;
    overflow: hidden;
    flex: 0 0 auto; /* Image takes up space as needed but doesn't grow */
}

/* Image scaling on hover */
.whenwhere .item .whenwhere-img img {
    width: 100%; /* Ensure the image covers the width of its container */
    height: auto; /* Maintain aspect ratio */
    -webkit-transition: all .5s;
    transition: all .5s;
}
.whenwhere .item .whenwhere-img:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/* Flexible content area */
.whenwhere .item .content {
    padding: 20px 20px 30px 20px;
    background: #fff;
    flex: 1 1 auto; /* Content fills the remaining space */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure space is distributed */
}

/* Styling for the tag */
.whenwhere .item .content .tag {
    margin-bottom: auto; /* Push the tag to the top */
}
.whenwhere .item .content .tag a {
    color: #888;
    font-style: italic;
}
.whenwhere .item .content .tag a:hover {
    color: #BD945A;
}

/* Title styling */
.whenwhere .item .content h5 {
    font-size: 21px;
    margin: 10px 0 15px 0;
    text-transform: uppercase;
}

/* Flexible paragraph */
.whenwhere .item .content p {
    flex: 1 1 auto; /* Allow paragraphs to fill the remaining space */
}

/* Icon color */
.whenwhere .item .content p i {
    color: #BD945A;
}

.whenwhere-entry {
    width: 100%;
    float: left;
    background: #fff;
    margin-bottom: 60px;
    box-shadow: 0 25px 75px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 768px) {
    .whenwhere-entry {
        margin-bottom: 45px;
    }
}
.whenwhere-entry .whenwhere-img {
    width: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
}
.whenwhere-entry .whenwhere-img img {
    position: relative;
    max-width: 100%;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.whenwhere-entry .desc {
    padding: 20px;
}
.whenwhere-entry .desc h3 {
    font-size: 24px;
    line-height: 1.2em;
    font-weight: 400;
    margin-bottom: 15px;
}
.whenwhere-entry .desc h3 a {
    color: #000;
    text-decoration: none;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.whenwhere-entry:hover .whenwhere-img img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}


/* =====  Button style  ===== */
.btn {
    padding: 0;
}
.buttono {
    font-family: 'Nunito Sans', sans-serif;
    background: #BD945A;
    border: 1px solid #BD945A;
    color: #fff;
    padding: 15px 30px;
    margin: 0;
    font-weight: 400;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 0px;
}
.buttono a {
    font-family: 'Nunito Sans', sans-serif;
    background: #BD945A;
    border: 1px solid #BD945A;
    color: #fff;
    padding: 10px 20px;
    margin: 0;
    font-weight: 500;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.buttono a:after {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 1;
    background: #fff;
    color: #000;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.buttono a span {
    position: relative;
    z-index: 2;
}
.buttono a:hover:after {
    width: 100%;
    left: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

.buttono a:hover,
.buttono a:hover span {
    color: #000;
}


/* ===== Footer style ===== */
#rsvp {
    background-image: url(./assets/images/banner-2.jpg);
}
.footer2 {
    padding: 90px 30px;
    background: #faf8f7;
}
.footer2 img { 
    width: 90px;
    margin-bottom: 10px;
}
.footer2 h2,
.footer2 h2 a {
    font-family: 'Cormorant Garamond', serif;
    font-size: 36px;
    font-weight: 500;
    display: block;
    width: 100%;
    position: relative;
    color: #000;
    line-height: 1em;
    letter-spacing: 0px;
    margin-bottom: 0;
}
.footer2 h2 a span,
.footer2 h2 span {
     display: block;
    font-family: 'Alex Brush', cursive;
    font-weight: 400;
    color: #000;
}
.footer2 h2 a i {
    color: #000;
    font-size: 16px;
    vertical-align: super;
}
.footer2 h6 {
    color: #888;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
}
.footer2 p {
    font-family: 'Cormorant Garamond', serif;
    font-size: 18px;
    font-style: italic;
    color: #888;
}
.footer2 .copyright {
    color: #888;
}
@media screen and (max-width: 768px) {
    .footer2 {
        padding: 100px 30px 80px 30px;
        text-align: center;
    }
}


/* ==== Preloader ==== */
.preloader{
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Heart */
.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  transform-origin: 32px 32px;
}
.lds-heart div {
  top: 23px;
  left: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  background: #BD945A;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: #BD945A;
}
.lds-heart div:before {
  left: -17px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -17px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}


/* ======= Blog & Post style ======= */
.blog .item {
    padding: 60px 30px;
    position: relative;
    background-size: cover;
    background-position: 50% 0% !important;
    margin-bottom: 30px;
}
.blog .item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 12, 12, 0.7);
}
.blog .item:hover:after {
    opacity: 0;
}
.blog .item:hover h5,
.blog .item:hover p,
.blog .item:hover span {
    color: #fff;
}
.blog .item:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
    -webkit-transition: all .4s;
    transition: all .4s;
}
.blog .item .content {
    position: relative;
    z-index: 7;
}
.blog .item h5 {
    font-size: 27px;
    line-height: 1.75em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 30px;
}
.blog .item .info {
    margin-bottom: 15px;
}
.blog .item .info span {
    margin-right: 10px;
    font-family: 'Old Standard TT', serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #AAA;
    font-weight: 400;
}
.blog .item .info span:last-child {
    margin: 0;
}
.blog .item .info span i {
    padding-right: 4px;
}
.blog .item p {
    margin-bottom: 30px;
}
.blog .item .more {
    font-weight: 500;
}
.blog .item .more:hover i {
    padding-left: 10px;
}
.blog .item .more i {
    padding-left: 2px;
    -webkit-transition: all .4s;
    transition: all .4s;
}
/* blog page post */
.blog-page .item {
    margin-bottom: 60px;
}
.blog-page .post-img {
    position: relative;
    overflow: hidden;
}
.blog-page .post-img:hover img {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}
.blog-page .post-img a {
    display: block;
}
.blog-page .post-img img {
    -webkit-transition: all .4s;
    transition: all .4s;
}
.blog-page .post-cont {
    padding: 20px 0 0 0;
    background-color: #fff;
}
.blog-page .post-cont .tag,
.blog-page .post-cont .date {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    color: #888;
    font-weight: 400;
    letter-spacing: 2px;
}
.blog-page .post-cont .tag:hover,
.blog-page .post-cont .date:hover {
  color: #BD945A;  
}
.blog-page .post-cont h5 {
    font-size: 27px;
    margin-bottom: 15px;
}
.blog-page .post-cont h5 a {
    color: #000;
}
.blog-page .post-cont h5 a:hover {
    color: #BD945A;
}
.blog-page .post-cont .info {
    margin-top: 0px;
    font-size: 12px;
    text-align: right;
}
.blog-page .post-cont .info a {
    font-size: 12px;
    color: #000;
}
.blog-page .post-cont .info a:last-of-type {
    float: right;
}
/* blog sidebar */
.blog-sidebar .widget {
    background: #faf8f7;
    padding: 30px;
    margin-bottom: 30px;
    overflow: hidden;
}
.blog-sidebar .widget ul {
    margin-bottom: 0;
    padding: 0;
}
.blog-sidebar .widget ul li {
    font-size: 15px;
    line-height: 1.5em;
    margin-bottom: 10px;
}
.blog-sidebar .widget ul li:hover {
    color: #BD945A;
}
.blog-sidebar .widget ul li a {
    color: #888;
}
.blog-sidebar .widget ul li a.active {
    color: #BD945A;
}
.blog-sidebar .widget ul li a:hover {
    color: #BD945A;
}
.blog-sidebar .widget ul li:last-child {
    margin-bottom: 0;
}
.blog-sidebar .widget ul li i {
    font-size: 9px;
    margin-right: 10px;
}
.blog-sidebar .widget .recent li {
    display: block;
    overflow: hidden;
}
.blog-sidebar .widget .recent .thum {
    width: 90px;
    overflow: hidden;
    float: left;
}
.blog-sidebar .widget .recent a {
    display: block;
    margin-left: 105px;
}
.blog-sidebar .widget-title {
    margin-bottom: 30px;
}
.blog-sidebar .widget-title h6 {
    padding-bottom: 0;
    border-bottom: 1px solid #f1eaea;
    font-size: 21px;
    line-height: 1.75em;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.blog-sidebar .search form {
    position: relative;
}
.blog-sidebar .search form input {
    width: 100%;
    padding: 10px;
    border: 0;
    background: #fff;
    margin-bottom: 0;
}
.blog-sidebar .search form button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    color: #000;
    border: 0;
    padding: 10px;
    cursor: pointer;
}
.blog-sidebar .tags li {
    margin: 3px !important;
    padding: 6px 16px;
    border: 1px solid #f1eaea;
    color: #888 !important;
    float: left;
}
.blog-sidebar ul.tags li a {
    font-size: 14px;
    color: #888;
}
.blog-sidebar ul.tags li:hover,
.blog-sidebar ul.tags li a:hover {
    color: #fff;
    background-color: #BD945A;
}
.blog-comment-section {
    padding-top: 60px;
    margin-bottom: 0px;
}
.blog-post-comment-wrap {
    display: flex;
    margin-bottom: 60px;
}
.blog-user-comment {
    margin-right: 30px;
    flex: 0 0 auto;
}
.blog-user-comment img {
    border-radius: 100%;
}
.blog-user-content {
    margin-right: 26px;
}
.blog-user-content h3 {
    font-size: 21px;
    color: #000;
    margin: 0 0 15px;
}
.blog-user-content h3 span {
    font-size: 11px;
    color: #888;
    font-weight: 400;
    margin-left: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Nunito Sans', sans-serif;
}
.blog-repay {
    font-size: 13px;
    line-height: 1.75em;
    color: #000 !important;
    margin: 0;
    font-weight: 400;
}
/* pagination */
.blog-pagination-wrap {
    padding: 0;
    margin: 30px 0 0 0;
    text-align: center;
}
.blog-pagination-wrap li {
    display: inline-block;
    margin: 0 5px;
    border-radius: 100%;
    border: 1px solid #f5eeeb;
}
.blog-pagination-wrap li a {
    background: #fff;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #888;
    font-weight: 300;
    border-radius: 100%;
}
.blog-pagination-wrap li a:hover {
    opacity: 1;
    text-decoration: none;
    background-color: #BD945A;
    border: 1px solid #BD945A;
    color: #fff;
}
.blog-pagination-wrap li a.active {
    background-color: #BD945A;
    border: 1px solid #BD945A;
    color: #fff;
}
.blog-pagination-wrap li a i { 
    font-size: 11px;
}
@media screen and (max-width: 768px) {
    .blog-pagination-wrap {
        padding: 0 0 60px 0;
        margin: 0;
        text-align: center;
    }
}



/* ==== Media Query ==== */
@media screen and (max-width: 991px) {
    
    .container {
        padding-right: 30px;
        padding-left: 30px;
    }
    .oliven-couple-title {
        font-size: 32px;
        line-height: 1.2em;
        margin-bottom: 15px;
    }
    .oliven-couple-subtitle {
        font-size: 18px;
    }
    #countdown .section-head h4 {
        font-size: 32px;
        line-height: 1em;
    }
    .seeyou .section-head h4 {
        font-size: 32px;
        margin-bottom: 5px;
        line-height: 1em;
    }
    .seeyou .section-head h3 {
        font-size: 32px;
    }  
      
    .p-40 {
        padding: 20px;
        margin: 0 15px;
    }
}

.custom-carousel .control-arrow {
    display: none;
}

.custom-carousel {
    touch-action: pan-x;
}

.custom-carousel .control-arrow {
    pointer-events: auto;
}

.custom-carousel {
    overflow: hidden; /* Prevents unwanted scrollbars */
}

.custom-carousel .slider-wrapper {
    overflow: visible !important; /* Allows horizontal scroll for the slider */
}

@media (min-width: 768px) {
    .custom-carousel .control-arrow {
        display: block;
        top: 50% !important;
        z-index: 2;
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        border: none;
        cursor: pointer;
    }

    .custom-carousel .control-prev.control-arrow {
        left: 10px;
    }

    .custom-carousel .control-next.control-arrow {
        right: 10px;
    }
}